export const environment = {
  production: true,
  apiUrl: '/api/patient-services',
  ivrUrl: 'https://ivr.mindwisehealth.com/api/ivr-services',
  referralUrl: 'https://referral.mindwisehealth.com/api/referral-services',
  labUrl: 'http://labs.mindwisehealth.com/api/vital-services',
  adminPortalUrl: 'https://admin.mindwisehealth.com',
  streamApiKey: 'znbpazwkscut',
  googleMapsApiKey: 'AIzaSyBRQux8xvWFFuuvjyrYuUpkb8s5mqtIni0',
  customFormsUrl: 'https://forms.mindwisehealth.com/api/dynamic-forms-services',
  httpInterceptor: {
    allowedList: [`/api/*`],
  },
  surveyJsLicenseKey:
    'OGJmYTEzNDYtYjc5Zi00ZWMzLWI4NDEtMDhmZmYxY2I4YzdmJmRvbWFpbnM6YnVuZGxlbi53ZWJzaXRlLG1pbmR3aXNlaGVhbHRoLmNvbSxhc3BlY3RuLmNvbSxtaXRlcmhlYWx0aC5jb207MT0yMDI1LTA3LTExLDI9MjAyNS0wNy0xMSw0PTIwMjUtMDctMTE=',
  referralFunctionality: true,
  enableLabOrders: false,
  treatmentSectionEnabled: true,
};
